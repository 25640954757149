import styled from 'styled-components';

import { device } from '../../helpers/screenSizes';

const ChartStyle = styled.div`
  background: ${props => props.theme.primaryBackground};
  height: 100%;
  flex: 3;
  border-bottom: solid 1px var(--separator);
  // margin: 0 auto;

  @media ${device.down.xs} {
    padding: 0;
  }

  @media ${device.down.md} {
    height: calc(100% - 60px);
  }

  @media screen and (orientation: landscape) {
    height: 100% !important;
  }

  .tv_chart_container {
    height: calc(100% - 40px);
    padding: 0px 1px 0px 1px;
    display: ${props => (props.activeTab === 'depth-chart' ? 'none' : 'block')};

    &.fullScreen {
      height: 100vh;
    }

    @media ${device.down.md} {
      height: calc(100% - 60px);
    }

    .chart-controls-bar-buttons a.active {
      color: ${props => props.theme.secondaryText} !important;
    }
  }

  .chart-widgets {
    padding-right: 0px;
    padding-left: 0px;
    .tab {
      @media ${device.up.lg} {
        width: 16%;
      }

      @media ${device.down.lg} {
        width: 20%;
      }

      @media ${device.down.xs} {
        width: 25%;
      }
    }
  }
`;

export const TvHeaderStyle = styled.div`
  &.trading-header-view-1 {
    margin-left: 0px !important;
  }

  @media ${device.down.md} {
    background-color: ${props => props.theme.primaryBackground};
    z-index: 0;
    padding: 5px;
    padding-left: 0px;
    padding-right: 5px;
    border-width: 1px 0px 1px 0px;
    border-color: var(--separator);
    border-style: solid;

    .mobile-time-frame {
      .Dropdown-menu {
        display: none !important;
      }
    }
    iframe {
      padding-bottom: 0px !important;
    }
    padding-left: 1% !important;

    .settings {
      svg {
        width: 50px;
        height: 20px;
        margin-bottom: 6px;
      }
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.primaryTheme} !important;
      }
    }

    .full-screen {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 6px;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme} !important;
      }
    }

    .snap-shot {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 6px;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme} !important;
      }
    }

    .open-new-tab {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 6px;
      }
      &:hover {
        color: ${props => props.theme.primaryTheme} !important;
      }
      img {
        position: relative;
        top: 2px;
      }
      padding-right: 0px;
    }
    .left-side-actions {
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .time-resolution {
      border-radius: 2px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      text-align: center;
      color: ${props => props.theme.secondaryText};
      .Dropdown-control {
        padding: 3px 14px 0px 0px;
        background-color: initial;
        cursor: pointer;
        border: none;
        color: ${props => props.theme.primaryTheme};
        height: 24px;
      }
      &.active-time-resolution {
        .is-selected {
          color: ${props => props.theme.primaryTheme} !important;
          border-radius: 2px;
        }
      }
      .arrow-open,
      .arrow-closed {
        top: 3px;
        right: 10px;
      }
    }
    .time-frame-view {
      display: flex;
      max-height: 24px;
    }

    .arrow-open,
    .arrow-closed {
      display: block;
      height: 0;
      margin-top: -ceil(2.5);
      position: absolute;
      right: 10px;
      top: 5px;
      width: 0;
    }
    .chart-types {
      margin-top: -2px;
      max-height: 20px;
      border-radius: 2px;
      background-color: ${props => props.theme.secondaryBackground};
      height: 15px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      text-align: center;
      color: ${props => props.theme.secondaryText};
      .arrow-open,
      .arrow-closed {
        top: 3px;
        right: 10px;
      }

      .Dropdown-control {
        position: relative;
        overflow: hidden;
        background-color: initial;
        border: none;
        cursor: pointer;
        box-sizing: border-box;
        color: ${props => props.theme.primaryTheme};
        cursor: default;
        outline: none;
        padding: 3px 3px 0px 3px;
        transition: all 200ms ease;
        .label-3Xqxy756- {
          display: none;
        }
      }

      .Dropdown-menu {
        background-color: ${props => props.theme.secondaryBackground};

        width: max-content;
      }

      .Dropdown-placeholder {
        &.is-selected {
          svg {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    .Dropdown-menu {
      border: 0px solid ${props => props.theme.secondaryText};
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 300px;
      overflow-y: auto;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1000;
      -webkit-overflow-scrolling: touch;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      background-color: ${props => props.theme.secondaryBackground};
      color: ${props => props.theme.secondaryText};
      .Dropdown-option {
        color: ${props => props.theme.secondaryText};
        padding: 5px 0px;
        &.is-selected {
          background-color: ${props => props.theme.primaryBackground};
        }
        &:hover {
          color: ${props => props.theme.primaryTheme};
        }
        .labelRow-3Q0rdE8-- {
          padding-top: 4px;
          font-family: var(--fontRegular);
          font-size: 12.1px;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: 0.6px;
          color: ${props => props.theme.secondaryText};
        }
      }
    }
    .compare-symbol {
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-top: -8px;
      }
    }
    .compare-linking {
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      svg {
        width: 22px;
        height: 22px;
        margin-top: -8px;
      }
    }

    .display-orders {
      cursor: pointer !important;

      svg {
        width: 17px;
        height: 17px;
        margin-bottom: 7px;
      }

      &.active {
        color: ${props => props.theme.primaryTheme} !important;
        svg {
          g {
            g {
              stroke: ${props => props.theme.primaryTheme} !important;
            }
          }
        }
      }
      color: ${props => props.theme.primaryText} !important;
      svg {
        g {
          g {
            stroke: ${props => props.theme.primaryText} !important;
          }
        }
      }
    }

    .col-3:not(:last-child) {
      max-height: 20px;
      flex: 0 0 14.2%;
      max-width: 14.2%;
      display: flex;
      padding-bottom: 12px;
      margin-right: 0px;
      justify-content: center;
      border-right: 0.3px solid rgba(45, 56, 66, 0.9);
    }
    .full-screen {
      cursor: pointer;
      padding-left: 8px;
    }
  }
  @media ${device.up.md} {
    max-height: 36px;
    background-color: ${props => props.theme.primaryBackground};
    z-index: 0;
    padding: 5px;
    padding-left: 22px;
    margin-right: 0px !important;
    border-bottom: 1px solid ${props => props.theme.separator};
    .arrow-open,
    .arrow-closed {
      display: block;
      height: 0;
      margin-top: -ceil(2.5);
      position: absolute;
      right: 10px;
      top: 5px;
      width: 0;
    }
    // Right Side action item Desktop

    .left-side-actions {
      display: flex;
      justify-content: flex-end;
      svg {
        width: 20px;
        height: 20px;
        position: relative;
        bottom: 2px;
      }

      .divider-section {
        height: 38px;
        border-right: 1px solid rgba(45, 56, 66, 0.9);
        margin-left: 12px;
        width: 1px;
        max-height: 23px;
        display: inline-block;
        margin-bottom: -8px;
      }
      .settings {
        cursor: pointer;
        padding-right: 12px;
        margin-top: -2px;
        &:hover {
          svg {
            g {
              g {
                g {
                  g {
                    stroke: ${props => props.theme.primaryTheme} !important;
                  }
                }
              }
            }
          }
        }
      }

      .full-screen {
        cursor: pointer;
        padding-right: 12px;
        margin-top: -2px;

        &:hover {
          svg {
            g {
              g {
                g {
                  g {
                    stroke: ${props => props.theme.primaryTheme} !important;
                  }
                }
              }
            }
          }
        }
      }
      .snap-shot {
        cursor: pointer;
        padding-right: 12px;
        margin-top: -2px;

        &:hover {
          svg {
            g {
              g {
                g {
                  g {
                    stroke: ${props => props.theme.primaryTheme} !important;
                  }
                }
              }
            }
          }
        }
      }
      .open-new-tab {
        cursor: pointer;
        padding-right: 12px;
        margin-top: -2px;

        &:hover {
          svg {
            g {
              g {
                g {
                  g {
                    stroke: ${props => props.theme.primaryTheme} !important;
                  }
                }
              }
            }
          }
        }
        padding-right: 0px;
      }
    }
    // Left Side action Items

    .display-orders {
      cursor: pointer !important;
      padding-left: 12px;
      /* margin-top: -6px; */
      svg {
        width: 17px;
        height: 17px;
        margin-top: -7px;
      }
      color: ${props => props.theme.primaryText} !important;
      svg {
        g {
          g {
            stroke: ${props => props.theme.primaryText} !important;
          }
        }
      }
      &:hover {
        color: ${props => props.theme.primaryTheme} !important;
        svg {
          g {
            g {
              stroke: ${props => props.theme.primaryTheme} !important;
            }
          }
        }
      }
      &.active {
        color: ${props => props.theme.primaryTheme} !important;
        svg {
          g {
            g {
              stroke: ${props => props.theme.primaryTheme} !important;
            }
          }
        }
      }
    }

    .time-frame-view {
      display: flex;
    }
    .time-resolution {
      border-radius: 2px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      text-align: center;
      color: ${props => props.theme.secondaryText};
      .arrow-open,
      .arrow-closed {
        display: block;
        height: 0;
        margin-top: -ceil(2.5);
        position: absolute;
        right: 10px;
        top: 2px;
        width: 0;
      }
      .Dropdown-control {
        padding: 2px 12px 8px 5px !important;
        background-color: initial;
        border: none;
        cursor: pointer;
        color: ${props => props.theme.primaryText};
        width: 48px;
        height: 22px;
      }
      &.active-time-resolution {
        background-color: ${props => props.theme.secondaryBackground};
        .is-selected {
          color: ${props => props.theme.primaryTheme} !important;
          border-radius: 2px;
        }
      }
    }

    .chart-types {
      max-height: 24px;
      border-radius: 2px;
      background-color: ${props => props.theme.primaryBackground};
      height: 15px;
      font-family: var(--fontRegular);
      font-size: 12.1px;
      text-align: center;
      color: ${props => props.theme.secondaryText};
      width: 35px;
      .arrow-open,
      .arrow-closed {
        top: 3px;
        right: 10px;
      }

      .Dropdown-control {
        max-height: 24px;
        position: relative;
        overflow: hidden;
        background-color: initial;
        border: none;
        cursor: pointer;
        box-sizing: border-box;
        color: var(--brand-text-primary);
        cursor: default;
        outline: none;
        padding: 3px 3px 0px 0px;
        transition: all 200ms ease;
        .label-3Xqxy756- {
          display: none;
        }
      }

      .Dropdown-menu {
        background-color: ${props => props.theme.secondaryBackground};

        width: max-content;
      }

      .Dropdown-placeholder {
        &.is-selected {
          svg {
            width: 20px;
            height: 20px;
            margin-bottom: 10px;
            margin-right: 5px;
          }
        }
      }
    }

    // Common Style for Drop Down

    .Dropdown-menu {
      border: 0px solid ${props => props.theme.secondaryText};
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 300px;
      overflow-y: auto;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1000;
      -webkit-overflow-scrolling: touch;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      background-color: v ${props => props.theme.secondaryBackground};
      color: ${props => props.theme.secondaryText};
      .Dropdown-option {
        color: ${props => props.theme.secondaryText};
        &.is-selected {
          background-color: ${props => props.theme.tertiaryBackground};
        }
        &:hover {
          color: ${props => props.theme.primaryTheme};
        }
        .labelRow-3Q0rdE8-- {
          padding-top: 4px;
          font-family: var(--fontRegular);
          font-size: 12.1px;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: 0.6px;
          color: ${props => props.theme.secondaryText};
        }
      }
    }

    // Indicator and Compare Symbol
    .compare-linking {
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      margin-top: -2px;

      svg {
        width: 22px;
        height: 22px;
      }
      &:hover {
        svg {
          g {
            g {
              g {
                g {
                  fill: ${props => props.theme.primaryTheme} !important;
                }
              }
            }
          }
        }
      }
    }

    .compare-symbol {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: -4px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
      &:hover {
        svg {
          g {
            g {
              g {
                g {
                  stroke: ${props => props.theme.primaryTheme} !important;
                }
              }
            }
          }
        }
      }
    }

    .save-chart {
      svg {
        width: 20px;
        height: 20px;
      }
      &:hover {
        svg {
          g {
            color: ${props => props.theme.primaryTheme};
          }
        }
      }
    }
  }

  .time-frame-dropdown {
    display: flex;
    max-height: 22px;
    flex: 0 0 30.33333%;
    max-width: 30.33333%;
    border-right: 0.3px solid rgba(45, 56, 66, 0.9);
    gap: 5px;
    .Dropdown-placeholder {
      color: ${props => props.theme.secondaryText};
    }
  }

  .display-order-bar-types {
    display: flex;
    .col:not(:last-child) {
      background: ${props => props.theme.primaryBackground};
      margin-right: 1px;
      max-height: 22px;
      display: flex;
      justify-content: space-between;
      max-width: 50px;
      border-right: 0.3px solid rgba(45, 56, 66, 0.9);
    }
  }

  .time-divider-section {
    border-right: 1px solid rgba(45, 56, 66, 0.9);
    height: 25px;
    width: 1px;
    left: 22px;
    padding-left: 12px;
    padding-top: 14px;
    margin-top: 4px;
  }
  .chart-divider-section {
    border-right: 1px solid rgba(45, 56, 66, 0.9);
    height: 25px;
    width: 1px;
    padding-top: 14px;
    margin-top: 4px;
    margin-left: -21px;
    cursor: pointer;
  }
  .indicator-divider-section {
    border-right: 1px solid rgba(45, 56, 66, 0.9);
    height: 25px;
    width: 1px;
    padding-top: 14px;
    margin-top: 4px;
    padding-left: 26px;
    cursor: pointer;
  }
  .linking-divider-section {
    border-right: 1px solid rgba(45, 56, 66, 0.9);
    height: 25px;
    width: 1px;
    padding-top: 14px;
    margin-top: 4px;
    padding-left: 10px;
  }
  .compare-linking-section {
    margin-right: 13px;
  }
  &.new-tab-tradingview {
    .time-frame-dropdown {
      display: flex;
      flex: 0 0 17.33333%;
      max-width: 17.33333%;
      border-right: 0.3px solid rgba(45, 56, 66, 0.9);
      .Dropdown-placeholder {
        color: ${props => props.theme.secondaryText};
      }
    }
  }
`;
export const TvStyle = styled.div`
  @media ${device.up.md} {
    height: 85vh;
    iframe {
      *width: 1px !important;
      min-width: 100% !important;
      width: 100% !important;
      *height: 1px !important;
      min-height: 100% !important;
      *height: 100% !important;
      // padding-bottom: 35px;
    }
  }
  @media ${device.down.md} {
    height: 50%;
    iframe {
      *width: 1px !important;
      min-width: 100% !important;
      width: 100% !important;
      *height: 1px !important;
      min-height: 100% !important;
      *height: 50% !important;
      padding-bottom: 18px;
    }

    &.mobile-full-screen {
      // potrait mode and full height and width
      height: 90vw;
    }

    &.w-65vh {
      width: 65vh;
    }

    &.w-100vh {
      width: 99vh;
    }

    &.fullScreen-mark {
      height: 100% !important;

      iframe {
        height: 90vh !important;
      }
    }
  }
`;

export const OptionStyle = styled.div`
  display: flex;
  .icon-type {
    display: flex;
    margin-right: 7px;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    svg {
      fill: var(--secondaryText);
    }
  }
  &:hover {
    color: ${props => props.theme.primaryTheme};
    svg {
      fill: var(--primaryTheme);
    }
  }
`;

export const ChartDropDownModalBody = styled.div`
  height: auto;
  border-radius: 2px;
  background-color: ${props => props.theme.primaryBackground};
  .symbols {
    button:nth-child(odd) {
      background-color: ${props => props.theme.primaryBackground} !important;
      border-bottom: 1px solid ${props => props.theme.separator} !important;
      &:hover {
        background: ${props => props.theme.secondaryBackground};
      }
    }
    button:nth-child(even) {
      background-color: ${props => props.theme.primaryBackground} !important;
      border-bottom: 1px solid ${props => props.theme.separator} !important;

      &:hover {
        background: ${props => props.theme.secondaryBackground};
      }
    }
    button {
      display: flex;
      justify-content: center;
      height: 30px;
      align-items: center;
      color: ${props => props.theme.secondaryText};
      font-size: 12.1px;
    }
  }
`;
export const ChartDropDownModalTitle = styled.div`
  height: 50px;
  //padding: 13px 20px 5px 22px;
  border-radius: 2px;
  background-color: ${props => props.theme.secondaryBackground};
  border-bottom: 1px solid ${props => props.theme.separator};
  h2 {
    font-family: var(--fontRegular);
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.6px;
    text-align: center;
    color: ${props => props.theme.secondaryText};
  }
  .cross-icon {
    position: absolute;
    top: 15px;
  }
  &.row {
    display: flex;
    .text {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .close-icon {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
`;

export default ChartStyle;
